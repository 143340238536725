body, #root {
  height: 100%;
}
#root {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
#main {
  display: flex;
  flex-direction: row;
  height: 100%;
}

body {
  color: black;
  overflow: hidden;
  height: 100%;
  padding: 0;
  margin: 0;
  background-image: radial-gradient(#ddd 3%, #fff 5%);
  background-position: 0 0;
  background-size: 49px 49px;
  width: 100%;
}