
div.col-actions {
  display: flex;
  flex-direction: column;
}

div.col-actions div.action-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 3px;
  cursor: pointer;
}

div.col-actions div.action-item .ico {
  width: 25px;
}

div.col-actions div.action-item:hover {
  background-color: #00000011;
}

div.col-actions div.action-item div.desc {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
  line-height: 10px;
  font-size: 13px;
  white-space: nowrap;
}

div.col-actions div.action-item div.desc .key-combo {
  font-size: 8px;
  white-space: nowrap;
  margin-top: 4px;
  display: flex;
  align-items: center;
}

div.tools {
  display: flex;
}

div.tools div {
  margin: 10px 20px;
  cursor: pointer;
  font-size: 20px;
}

div.color-buttons {
  display: flex;
  padding: 8px;
}

div.color-buttons button.color {
  border: 1px solid;
  margin: 10px 20px;
  width: 20px;
  padding: 0;
  height: 20px;
  cursor: pointer;
  border-radius: 100px;
}

div.color-buttons button.color:hover {
  -webkit-box-shadow: 2px ​2px 4px -1px #555;
  box-shadow: 2px ​2px 4px -1px #555;
}

svg.shadow-shape {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
}

input#text-shadow-shape {
  outline: none;
  border: 2px dotted aqua;
  position: absolute;
  z-index: -1;
  padding: 5px;
  margin-top: -10px;
  margin-left: -10px;
}

.action-menu.disabled, .action-menu.disabled * {
  color: #aaa;
  cursor: not-allowed !important;
}
div.action-menu.action .title {
    display: none;
}
  
div.action-menu div.button .ico {
  display: flex;
  justify-content: center;
}
  div.action-menu div.button {
    font-size: 22px;
    cursor: pointer;
    border-radius: 10px;
    margin: 2px;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  div.action-menu div.button .desc {
    font-size: 10px;
    color: #333;
  }
  
  div.action-menu div.button:hover {
    background-color: #00000011;
  }
  
  div.action-menu div.action-panel-container {
    display: block;
    position: relative;
  }
  
  div.action-menu div.action-panel-container div.action-panel {
    top: -55px;
    align-items: center;
    padding: 15px;
    flex-direction: column;
    visibility: hidden;
    position: absolute;
    background-color: #eee;
    border-radius: 10px;
    display: flex;
    transition: left 150ms linear, opacity 50ms ease-out;
    color: black;
    opacity: 0;
    left: 90px;
  }
  
  div.action-menu div.action-panel-container div.action-panel.active {
    visibility: inherit;
    left: 60px;
    opacity: 1;
  }

  .share {
    display: flex;
  }
  .share i {
    font-size: 18px;
  }
  .share-link .title {
    font-size: 14px !important;
    margin-top: 4px;
    margin-bottom: -4px;
  }

  .share input {
    margin-right: 4px;
    font-size: 18px;
  }

  div.action-menu.no-title div.action-panel-container div.action-panel.active {
    visibility: inherit;
    margin-top: 15px;
    opacity: 1;
  }

  div.left-panel {
    top: 10px;
    width: 62px;
    -webkit-box-shadow: 5px 5px 30px -20px teal;
    border: 1px solid teal;
    padding: 8px;
    flex-direction: column;
    position: absolute;
    z-index: 2;
    box-shadow: 5px 5px 30px -20px teal;
    background-color: #eee;
    border-radius: 10px;
    display: flex;
    left: 10px;
  }
  