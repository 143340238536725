div.snaps {
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 0;
    max-height: 90%;
    user-select: none;
    left: 20px;
    background: #eee;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border: 1px solid teal;
    border-bottom-width: 0;
    padding: 10px;
    padding-top: 4px;
    div.tray-header {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        position: relative;
        div.snap-animation {
            position: absolute;
            &.start {
                right: 15px;
                bottom: 90px;
                font-size: 70px;
                opacity: 0.5
            }
            &.finish {
                transition: 800ms;
                bottom: -10px;
                opacity: 0;
                right: 30px;
                font-size: 30px;
            }
        }
        .expanded {
            margin-left: 10px;
            padding-right: 20px;
            .ico {
                position: absolute;
                top: 5px;
                right: 13px;
                color: #999
            }
        }
    }
    img.snap-img {
        background-color: white;
        width: 150px;
        height: 150px;
    }
}