/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fa-triangle.tool {
  /* find out why font awesome isn't rotating */
  -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

/* .fa-secondary { color: red } */
.fa-primary {
  color: teal
}

.btn {
  border: 1px solid black;
  border-radius: 4px;
  padding: 5px 15px;
  background-color: white;
}

.btn:hover {
  transition: all;
  background-color: #dadada;
}