div.welcome {
    right: 0;
    top: 0;
    justify-content: center;
    font-family: cursive;
    align-items: center;
    position: absolute;
    z-index: -2;
    font-size: 80px;
    display: flex;
    bottom: 0;
    color: #8888ff88;
    left: 0;
}