.remote-users-tile {
    position: fixed;
    bottom: 10px;
    right: -1px;
}
.remote-users-tile .body {
    padding: 10px 20px;
    border: 1px solid teal;
    background-color: #33333310;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    flex-direction: column;
    -webkit-box-shadow: -5px -5px 30px -20px teal;
    box-shadow: -5px -5px 30px -20px teal;
}
.remote-users-tile .title {
    font-size: 12px;
    text-align: center;
    padding-left: 2px;;
    /* padding-bottom: 2px; */
    color: green;
}

.remote-users-tile .user-row {
    cursor: default;
    user-select: none;
    display: flex;
    align-items: center;
}

.remote-users-tile .user-row i {
    margin-right: 10px;
}